import React, { useState, useMemo } from 'react';
import { ChevronRight, Plus, Minus, Search, X, Calendar, Lock } from 'lucide-react';

const TechNode = ({ title, children, icons, subTechnologies, onSubTechClick }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubTechClick = (event, tech) => {
    event.stopPropagation();
    onSubTechClick(tech);
  };

  return (
    <div className="bg-white border border-gray-200 rounded-lg p-4 m-2 w-80 shadow-sm">
      <div className="flex justify-between items-center">
        <h3 className="font-bold text-lg text-gray-800">{title}</h3>
        <button onClick={() => setIsExpanded(!isExpanded)} className="text-blue-600">
          {isExpanded ? <Minus size={20} /> : <Plus size={20} />}
        </button>
      </div>
      <div className="flex space-x-1 mt-2">
        {icons.map((Icon, index) => (
          <Icon key={index} size={16} className="text-blue-600" />
        ))}
      </div>
      <p className="text-sm text-gray-600 mt-2">{children}</p>
      {isExpanded && subTechnologies && (
        <div className="mt-4 space-y-2">
          {subTechnologies.map((tech, index) => (
            <button
              key={index}
              onClick={(event) => handleSubTechClick(event, tech.name)}
              className="bg-gray-100 p-2 rounded-md text-sm text-gray-700 w-full text-left hover:bg-gray-200 transition-colors flex justify-between items-center"
            >
              <span>{tech.name}</span>
              <span className="text-xs text-gray-500 flex items-center">
                <Calendar size={12} className="mr-1" />
                {tech.date}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const DetailedExplanation = ({ technology, onClose }) => {
  const explanations = {
    "Enhanced integrations": "Tribble is expanding its integration capabilities with Salesforce, Seismic, and Highspot to provide seamless data flow and improved functionality across platforms.",
    "Knowledge graph": "A sophisticated knowledge graph is being developed to incorporate both structured and unstructured data, enhancing Tribble's ability to understand and utilize complex information.",
    "Automated expert engagement": "This feature will enable automatic outreach to subject matter experts for information retrieval, improving the accuracy and depth of Tribble's knowledge base.",
    "Multi-modal content ingestion": "Tribble is implementing advanced ingestion capabilities for various content types including videos, webinars, audio, and YouTube playlists.",
    "End-to-end proposal drafting": "A patent-pending feature that will allow Tribble to intake multiple RFP documents and output a completed proposal draft, streamlining the entire process.",
    "Content creation tools": "Integration with tools like Slides API and Docs API to facilitate content creation, supporting the end-to-end proposal drafting process.",
    "Enablement journeys": "Custom learning paths for different user groups, including features like 'Learn More', 'Related Facts', and simulations for Account Executives.",
    "Multi-modal conversations": "Enhanced conversational abilities allowing Tribble to retrieve and present various media types such as images, architecture diagrams, and specific slides.",
    "Enablement gap analytics": "Advanced analytics to identify and address gaps in sales enablement, leading to targeted content generation and retrieval.",
    "Content gap analytics": "Analysis tools to identify missing or outdated content, prompting creation or updates as needed.",
    "Conversational analytics": "An AI-powered chat interface for executives to gain insights and actionable intelligence from Tribble's data.",
    "Insight-based re-engagement": "Automated loops that use analytics insights to drive re-engagement with customers or internal teams.",
    "Independent CMS capabilities": "Future development to allow Tribble to operate independently of external CMSs, with all necessary content loaded and managed directly within the platform.",
    "On-demand content formatting": "Ability to dynamically shape content into various required formats such as PDFs, proposals, decks, or Confluence pages.",
    "Expanded content generation": "Potential future capabilities for creating various content types including decks, messaging, narratives, and outbound email drafting."
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-6 shadow-lg">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-gray-800">{technology}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="text-gray-600">{explanations[technology] || "Detailed explanation coming soon."}</p>
      </div>
    </div>
  );
};

const TechTree = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleLogin = (e) => {
    e.preventDefault(); // Prevent form submission
    if (password === 'Tribble2024MakeitSo') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const techNodes = [
    {
      title: "Tribble Brain",
      icons: [ChevronRight, ChevronRight, ChevronRight, ChevronRight],
      subTechnologies: [
        { name: "Enhanced integrations", date: "Q4 2024" },
        { name: "Multi-modal content ingestion", date: "Q4 2024" },
        { name: "Knowledge graph", date: "Q1 2025" },
        { name: "Automated expert engagement", date: "Q1 2025" },
        { name: "Independent CMS capabilities", date: "Q2 2025" }
      ],
      description: "Core knowledge processing, storage, and content management"
    },
    {
      title: "Tribble Agent",
      icons: [ChevronRight, ChevronRight, ChevronRight, ChevronRight],
      subTechnologies: [
        { name: "End-to-end proposal drafting", date: "Q4 2024" },
        { name: "Content creation tools", date: "Q1 2025" },
        { name: "Enablement journeys", date: "Q1 2025" },
        { name: "Multi-modal conversations", date: "Q2 2025" },
        { name: "On-demand content formatting", date: "Q2 2025" },
        { name: "Expanded content generation", date: "Future" }
      ],
      description: "AI-powered assistant capabilities and content creation"
    },
    {
      title: "Tribblytics",
      icons: [ChevronRight, ChevronRight, ChevronRight],
      subTechnologies: [
        { name: "Enablement gap analytics", date: "Q1 2025" },
        { name: "Content gap analytics", date: "Q1 2025" },
        { name: "Conversational analytics", date: "Q2 2025" },
        { name: "Insight-based re-engagement", date: "Q2 2025" }
      ],
      description: "Advanced analytics, insights, and content optimization"
    }
  ];

  const filteredTechNodes = useMemo(() => {
    return techNodes.filter(node =>
      node.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      node.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      node.subTechnologies.some(tech => tech.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  const handleSubTechClick = (tech) => {
    setSelectedTechnology(tech);
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <form onSubmit={handleLogin} className="bg-white p-8 rounded-lg shadow-md">
          <div className="flex items-center justify-center mb-4">
            <Lock size={24} className="text-blue-600 mr-2" />
            <h2 className="text-2xl font-bold text-gray-900">Password Protected</h2>
          </div>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
          >
            Access Tech Tree
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 p-8 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Tribble Technology Tree</h2>
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            <input
              type="text"
              placeholder="Search technologies..."
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          {filteredTechNodes.map((node, index) => (
            <TechNode
              key={index}
              title={node.title}
              icons={node.icons}
              subTechnologies={node.subTechnologies}
              onSubTechClick={handleSubTechClick}
            >
              {node.description}
            </TechNode>
          ))}
        </div>
      </div>
      {selectedTechnology && (
        <DetailedExplanation
          technology={selectedTechnology}
          onClose={() => setSelectedTechnology(null)}
        />
      )}
    </div>
  );
};

export default TechTree;
